import Cookie from 'js-cookie';

class LanguageModal {
    constructor(selector) {
        this.selector = selector;
        this.body = document.body;
        this.close_buttons = this.selector.querySelectorAll('[data-element="close"]');
        this.browser_lang = window.navigator.language;
        this.browser_lang = this.browser_lang.split('-')[0];
        this.current_lang = this.selector.getAttribute('data-current-lang');
        this.content = this.selector.querySelector('[data-element="content"]');
        this.links = window.lang_links;
        this.lang_list_items = this.selector.querySelectorAll('[data-lang]');
        this.link_text = {
            'pl' : 'polski',
            'en' : 'angielski',
            'id' : 'indonezyjski'
        };

        this._clickEvent = this._clickEvent.bind(this);

        // if (this.browser_lang !== this.current_lang) {
            let sugested = this.links[window.default_lang];

            Object.keys(this.links).forEach(id => {
                let link = this.links[id];
                if (link.lang === this.browser_lang) {
                    sugested = link;
                }
            });

            // this.content.querySelector('span').innerHTML = this.link_text[this.browser_lang];
            let lang_link = this.content.querySelector('[data-lang-link]')
            if (lang_link !== null) {
                lang_link.setAttribute('href', sugested.url);
                let flag = lang_link.querySelector('.flag');
                if (flag !== null) {
                    flag.classList.add('flag-' + sugested.flag);
                }
                let span = lang_link.querySelector('span');
                if (span !== null) {
                    span.innerHTML = sugested.name;
                }
                // let hide = this.content.querySelector('[data-lang="' + sugested_lang + '"]');
                // if (hide !== null) {
                //     hide.style.display = 'none';
                // }
            }

            if (this.close_buttons !== null) {
                this.close_buttons.forEach(btn => {
                    btn.addEventListener('click', this._clickEvent);
                });
            }

            if (this.lang_list_items !== null) {
                this.lang_list_items.forEach(item => {
                    item.addEventListener('click', e => {
                        Cookie.set('lnm_cookie', true, { expires: 365 });
                    });
                });
            }

            setTimeout(() => {
                this.body.classList.add('overlay');
                this.selector.classList.add('modal--shown');

                let specifiedElement = this.selector;
                document.addEventListener('click', function(e) {
                    let isClickInside = specifiedElement.contains(e.target);

                    if (!isClickInside) {
                        document.body.classList.remove('overlay');
                        specifiedElement.classList.remove('modal--shown');
                        Cookie.set('lnm_cookie', true, { expires: 365 });
                    }
                });
            }, 500);
        // }
    }
    _clickEvent(e) {
        e.preventDefault();

        if (this.body.classList.contains('overlay')) {
            this.body.classList.remove('overlay');
        }

        if (this.selector.classList.contains('modal--shown')) {
            this.selector.classList.remove('modal--shown');
        }
        Cookie.set('lnm_cookie', true, { expires: 365 });
    }
}
export default LanguageModal;