/**
 *  Created by Michał on 11.05.2017.
 */
import Glide from "@glidejs/glide/entry/entry-complete";
import SimpleLightbox from "simple-lightbox/dist/simpleLightbox.min";
import Faq from "./faq";
import BackToTop from "./backToTop";
import map from './map';
import VideoPlayer from "./videoPlayer";
import CarouselVideoPlayer from "./carouselVideoPlayer";
import LanguageModal from "./languageModal";

function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

// // let header = document.querySelector('.js-header');
// let body = document.querySelector('body');
// // let header_height_non_sticky = 0;

window.addEventListener('DOMContentLoaded', () => {

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 200;
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 20;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);

    let gc = null;
    let gallery_carousel = document.querySelector('.js-lesson-gallery');
    if (gallery_carousel !== null) {
        gc = new Glide(gallery_carousel, {
            type: 'carousel',
            perView: 1,
            autoplay: 5000,
            animationDuration: 500,
            gap: 0,
            //clones: false,
            animationTimingFunc: 'linear'
        }).mount();
    }

    let faqs = document.querySelectorAll('.js-faq');
    if (faqs !== null) {
        faqs.forEach(f => {
            new Faq(f);
        });
    }

    let btt_button = document.querySelector('.js-back-to-top');
    if (btt_button !== null) {
        new BackToTop(btt_button);
    }

    let videos = document.querySelectorAll('.js-video-player');
    if (videos !== null) {
        videos.forEach(v => {
           new VideoPlayer(v);
        });
    }

    let carousel_videos = document.querySelectorAll('.js-carousel-video-player');
    if (carousel_videos !== null) {
        carousel_videos.forEach(cv => {
           new CarouselVideoPlayer(cv);
        });
    }

    let mfc = null;
    let moments_from_class = document.querySelector('.js-moments-from-class');
    if (moments_from_class !== null) {
        mfc = new Glide(moments_from_class, {
            type: 'carousel',
            perView: 4,
            autoplay: 5000,
            animationDuration: 500,
            gap: 20,
            animationTimingFunc: 'linear',
            breakpoints: {
                600: {
                    perView: 2
                },
                1000: {
                    perView: 3
                }
            }
        }).mount();
    }

    let fic = null;
    let featured_in_carousel = document.querySelector('.js-featured-in-carousel');
    if (featured_in_carousel !== null) {
        let prev_button = featured_in_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = featured_in_carousel.parentNode.querySelector('[data-element="button-next"]');

        fic = new Glide(featured_in_carousel, {
            type: 'carousel',
            perView: 5,
            autoplay: 3000,
            animationDuration: 500,
            gap: 20,
            animationTimingFunc: 'linear',
            breakpoints: {
                400: {
                    perView: 1
                },
                700: {
                  perView: 2
                },
                991: {
                  perView: 3
                },
                1199: {
                    perView: 4
                }
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            fic.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            fic.go('>');
        });
    }

    let lightbox = new SimpleLightbox({elements: '.js-moments-from-class a'});
    let lightbox_gallery = new SimpleLightbox({elements: '.js-creations-gallery a'});

    let cec = null;
    let charity_events_carousel = document.querySelector('.js-charity-events');
    if (charity_events_carousel !== null) {
        let prev_button = charity_events_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = charity_events_carousel.parentNode.querySelector('[data-element="button-next"]');

        cec = new Glide(charity_events_carousel, {
            type: 'slider',
            perView: 3,
            autoplay: 0,
            animationDuration: 500,
            gap: 70,
            bound: true,
            animationTimingFunc: 'linear',
            breakpoints: {
                500: {
                    autoplay: 4500,
                    perView: 1,
                },
                767: {
                    autoplay: 0,
                    perView: 2,
                    gap: 20
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            cec.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            cec.go('>');
        });

        let count = charity_events_carousel.querySelectorAll('.glide__slide').length;
        if (window.innerWidth <= 500) {
            if (count <= 1) {
                prev_button.style.display = 'none';
                next_button.style.display = 'none';
            }
        } else if (window.innerWidth >= 501 && window.innerWidth <= 767) {
            if (count <= 2) {
                prev_button.style.display = 'none';
                next_button.style.display = 'none';
            }
        } else if (window.innerWidth >= 768) {
            if (count <= 3) {
                prev_button.style.display = 'none';
                next_button.style.display = 'none';
            }
        }
    }

    let vidc = null;
    let videos_carousel = document.querySelector('.js-videos');
    if (videos_carousel !== null) {
        let prev_button = videos_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = videos_carousel.parentNode.querySelector('[data-element="button-next"]');

        vidc = new Glide(videos_carousel, {
            type: 'slider',
            perView: 3,
            autoplay: 0,
            animationDuration: 500,
            gap: 70,
            bound: true,
            animationTimingFunc: 'linear',
            breakpoints: {
                500: {
                    autoplay: 4500,
                    perView: 1,
                },
                767: {
                    autoplay: 0,
                    perView: 2,
                    gap: 20
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            vidc.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            vidc.go('>');
        });

        let count = videos_carousel.querySelectorAll('.glide__slide').length;
        if (window.innerWidth <= 500) {
            if (count <= 1) {
                prev_button.style.display = 'none';
                next_button.style.display = 'none';
            }
        } else if (window.innerWidth >= 501 && window.innerWidth <= 767) {
            if (count <= 2) {
                prev_button.style.display = 'none';
                next_button.style.display = 'none';
            }
        } else if (window.innerWidth >= 768) {
            if (count <= 3) {
                prev_button.style.display = 'none';
                next_button.style.display = 'none';
            }
        }
    }

    let motc = null;
    let mot_carousel = document.querySelector('.js-meet-our-team');
    if (mot_carousel !== null) {
        let prev_button = mot_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = mot_carousel.parentNode.querySelector('[data-element="button-next"]');

        motc = new Glide(mot_carousel, {
            type: 'slider',
            perView: 3,
            autoplay: 0,
            animationDuration: 500,
            gap: 70,
            bound: true,
            animationTimingFunc: 'linear',
            breakpoints: {
                500: {
                    autoplay: 4500,
                    perView: 1,
                },
                767: {
                    autoplay: 0,
                    perView: 2,
                    gap: 20
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            motc.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            motc.go('>');
        });

        let count = mot_carousel.querySelectorAll('.glide__slide').length;
        if (window.innerWidth <= 500) {
            if (count <= 1) {
                prev_button.style.display = 'none';
                next_button.style.display = 'none';
            }
        } else if (window.innerWidth >= 501 && window.innerWidth <= 767) {
            if (count <= 2) {
                prev_button.style.display = 'none';
                next_button.style.display = 'none';
            }
        } else if (window.innerWidth >= 768) {
            if (count <= 3) {
                prev_button.style.display = 'none';
                next_button.style.display = 'none';
            }
        }
    }

    let uec = null;
    let ue_carousel = document.querySelector('.js-upcoming-events');
    if (ue_carousel !== null) {
        let prev_button = ue_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = ue_carousel.parentNode.querySelector('[data-element="button-next"]');

        uec = new Glide(ue_carousel, {
            type: 'slider',
            perView: 1,
            autoplay: 0,
            animationDuration: 500,
            gap: 70,
            bound: true,
            animationTimingFunc: 'linear'
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            uec.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            uec.go('>');
        });
    }

    let lang_modal = document.querySelector('.js-language-modal');
    if (lang_modal !== null) {
        new LanguageModal(lang_modal);
    }


    // let maps = document.querySelectorAll('.js-map');
    // if (maps.length > 0) {
    //     maps.forEach(map => {
    //         new Map(map);
    //     });
    // }

    //
    // let youtube_players = document.querySelectorAll('.js-youtube-player');
    // if (youtube_players.length > 0) {
    //     youtube_players.forEach(player => {
    //         new YoutubePlayer(player);
    //     });
    // }
    //
    // let posts_lists = document.querySelectorAll('.js-posts-list');
    // if (posts_lists.length > 0) {
    //     posts_lists.forEach(item => {
    //         new PostsList(item);
    //     });
    // }
    //
    // let handleError = (error, list) => {
    //     let element = document.createElement('div');
    //
    //     const modal = document.createElement('div');
    //     const close = document.createElement('a');
    //     close.classList.add('modal__close');
    //     close.innerHTML = '×';
    //
    //     modal.classList.add('modal');
    //     modal.classList.add('modal--load-posts-error');
    //     modal.setAttribute('data-element', 'modal');
    //     modal.innerText = 'Wystąpił błąd podczas ładowania publikacji, odśwież stronę i spróbuj ponownie.';
    //
    //     modal.appendChild(close);
    //     element.appendChild(modal);
    //
    //     let instance = basicLightbox.create(element);
    //     instance.show();
    //
    //     close.addEventListener('click', (e) => {
    //         instance.close();
    //         instance.innerHTML = '';
    //     });
    // };
    //
    // let news_post_list = document.querySelector('.js-news-loading');
    // if (news_post_list !== null) {
    //     new PublicationsList(news_post_list, {
    //         generatePost: (post, list) => {
    //
    //             let element = document.createElement('div');
    //             element.classList.add('single-post');
    //             element.classList.add('single-post--news');
    //
    //             let img_src = post._embedded["wp:featuredmedia"][0].source_url;
    //             let img = document.createElement('img');
    //             img.classList.add('single-post__thumbnail');
    //             img.setAttribute('alt', post.title.rendered);
    //             if (img_src !== '') {
    //                 img.setAttribute('src', img_src);
    //                 element.append(img);
    //             }
    //
    //             let title = document.createElement('div');
    //             title.classList.add('single-post__title');
    //             title.innerHTML = post.title.rendered;
    //
    //             let content = document.createElement('div');
    //             content.classList.add('single-post__content');
    //             content.innerHTML = post.content.rendered;
    //
    //             element.append(title);
    //             element.append(content);
    //             return element;
    //         },
    //         noResults: (list) => {
    //             let element = document.createElement('div');
    //             element.classList.add('title');
    //             element.innerText = 'Brak wpisów spełniających kryteria';
    //             return element;
    //         },
    //         handleError: handleError,
    //         activeLoaderClass: 'active'
    //     });
    // }
    //
    // let article_post_list = document.querySelector('.js-articles-loading');
    // if (article_post_list !== null) {
    //     new PublicationsList(article_post_list, {
    //         generatePost: (post, list) => {
    //             let element = document.createElement('div');
    //             element.classList.add('single-post');
    //             element.setAttribute('data-element', 'single-post');
    //             element.setAttribute('data-post-id', post.id);
    //
    //             let title = document.createElement('div');
    //             title.classList.add('single-post__title');
    //             title.innerHTML = post.title.rendered;
    //
    //             let content = document.createElement('div');
    //             content.classList.add('single-post__content');
    //             content.innerHTML = post.content.rendered;
    //
    //             let loader_ctnr = document.createElement('div');
    //             loader_ctnr.classList.add('single-post__loader');
    //             loader_ctnr.setAttribute('data-element', 'loader');
    //
    //             let loader = document.createElement('div');
    //             loader.classList.add('lds-roller');
    //
    //             let l_1 = document.createElement('div');
    //             let l_2 = document.createElement('div');
    //             let l_3 = document.createElement('div');
    //             let l_4 = document.createElement('div');
    //             let l_5 = document.createElement('div');
    //             let l_6 = document.createElement('div');
    //             let l_7 = document.createElement('div');
    //             let l_8 = document.createElement('div');
    //
    //             loader.append(l_1);
    //             loader.append(l_2);
    //             loader.append(l_3);
    //             loader.append(l_4);
    //             loader.append(l_5);
    //             loader.append(l_6);
    //             loader.append(l_7);
    //             loader.append(l_8);
    //
    //             loader_ctnr.append(loader);
    //
    //             element.append(title);
    //             element.append(content);
    //             element.append(loader_ctnr);
    //
    //             new PostsListSingle(element);
    //
    //             return element;
    //         },
    //         noResults: (list) => {
    //             let element = document.createElement('div');
    //             element.classList.add('title');
    //             element.innerText = 'Brak wpisów spełniających kryteria';
    //             return element;
    //         },
    //         handleError: handleError,
    //         activeLoaderClass: 'active'
    //     });
    // }
    //
    // let am_post_list = document.querySelector('.js-additional-materials-loading');
    // if (am_post_list !== null) {
    //     new PublicationsList(am_post_list, {
    //         generatePost: (post, list) => {
    //
    //             let element = document.createElement('div');
    //             element.classList.add('single-post');
    //
    //             let title = document.createElement('div');
    //             title.classList.add('single-post__title');
    //             title.innerHTML = post.title.rendered;
    //
    //             let content = document.createElement('div');
    //             content.classList.add('single-post__content');
    //             content.innerHTML = post.content.rendered;
    //
    //             element.append(title);
    //             element.append(content);
    //
    //             let url = post.meta._nt_additional_material_file;
    //             let ext = url.split('.').pop();
    //
    //             let download = document.createElement('div');
    //             download.classList.add('single-post__download');
    //
    //             let span = document.createElement('span');
    //             if (ext !== '') {
    //                 span.innerText = ext;
    //             }
    //
    //             let link = document.createElement('a');
    //             if (url !== '') {
    //                 link.setAttribute('href', url);
    //                 link.setAttribute('target', '_blank');
    //                 link.innerText = 'Pobierz';
    //             }
    //
    //             download.append(span);
    //             download.append(link);
    //
    //             element.append(download);
    //             return element;
    //         },
    //         noResults: (list) => {
    //             let element = document.createElement('div');
    //             element.classList.add('title');
    //             element.innerText = 'Brak wpisów spełniających kryteria';
    //             return element;
    //         },
    //         handleError: handleError,
    //         activeLoaderClass: 'active'
    //     });
    // }
    //
    // let lesson_maps = document.querySelectorAll('.js-lesson-map');
    // if (lesson_maps.length > 0) {
    //     lesson_maps.forEach(lm => {
    //         new LessonMap(lm);
    //     })
    // }
    //
    // let lesson_audios = document.querySelectorAll('.js-lesson-audio');
    // if (lesson_audios.length > 0) {
    //     lesson_audios.forEach(audio => {
    //         new LessonAudio(audio);
    //     });
    // }
    //
    // let quizes = document.querySelectorAll('.js-quiz');
    // if (quizes.length > 0) {
    //     quizes.forEach(quiz => {
    //         new Quiz(quiz);
    //     });
    // }
    // let mainquizes = document.querySelectorAll('.js-main-quiz');
    // if (mainquizes.length > 0) {
    //     mainquizes.forEach(quiz => {
    //         new MainQuiz(quiz);
    //     });
    // }
    //
    // let coloring_books = document.querySelectorAll('.js-coloring-book');
    // if (coloring_books.length > 0) {
    //     coloring_books.forEach(book => {
    //         new ColoringBook(book);
    //     });
    // }
    //
    // // let lesson_gallery = document.querySelectorAll('.nt-lightbox a');
    // // if(lesson_gallery !== null) {
    // //     lesson_gallery.forEach(el => {
    // //         let instance = GLightbox({
    // //             elements: lesson_gallery,
    // //         });
    // //     });
    // // }
    // new GLightbox();
});

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 200;
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});

window.addEventListener('load', () => {
    let opc = null;
    let opinions_carousel = document.querySelector('.js-opinions');
    if (opinions_carousel !== null) {
        let prev_button = opinions_carousel.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = opinions_carousel.parentNode.querySelector('[data-element="button-next"]');

        opc = new Glide(opinions_carousel, {
            type: 'carousel',
            perView: 2,
            autoplay: false,
            bound: true,
            startAt: 0,
            gap: 75,
            breakpoints: {
                1199: {
                    perView: 1
                },
                640: {
                    perView: 1,
                    autoplay: 3000
                }
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            opc.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            opc.go('>');
        });
    }
});