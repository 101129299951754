import * as basicLightbox from "basiclightbox";
class CarouselVideoPlayer {
    constructor(selector) {
        this.selector = selector;
        this.video_id = this.selector.getAttribute('data-video-id');

        this._showPlayer = this._showPlayer.bind(this);

        if (this.selector !== null && this.video_id !== '') {
            this.selector.addEventListener('click', this._showPlayer);
        }
    }
    _showPlayer(e) {
        e.preventDefault();

        let w = window.innerWidth;
        let h = (w / 16) * 9;
        let modify = 0.8;

        if (window.innerWidth <= 600) {
            w = window.innerWidth;
            h = (w / 16) * 9;
            modify = 1;
        }

        const div = document.createElement('div');
        const modal = document.createElement('div');
        modal.classList.add('modal-video');
        modal.setAttribute('data-element', 'modal');

        const video = document.createElement('iframe');
        video.setAttribute('src', 'https://www.youtube.com/embed/' + this.video_id + '?controls=0&rel=0&autoplay=1');
        video.setAttribute('width', w * modify);
        video.setAttribute('height', h * modify);
        video.setAttribute('frameborder', 0);

        modal.appendChild(video);
        div.appendChild(modal);

        let instance = basicLightbox.create(div);
        instance.show();
    }
}
export default CarouselVideoPlayer;