class Faq {
    constructor(selector) {
        this.selector = selector;
        this.elements = this.selector.querySelectorAll('[data-element="single"]');

        this._click = this._click.bind(this);

        if (this.elements !== null) {
            this.elements.forEach(el => {
                let question = el.querySelector('[data-element="question"]');
                question.addEventListener('click', this._click);
            });
        }
    }
    _click(e) {
        e.preventDefault();

        console.log('click');

        if (e.target.parentNode.classList.contains('faq__single--active')) {
            this._hideAllActiveQuestions();
        } else {
            this._hideAllActiveQuestions();
            e.target.parentNode.classList.add('faq__single--active');
        }
    }
    _hideAllActiveQuestions() {
        this.elements.forEach(el => {
            if (el.classList.contains('faq__single--active')) {
                el.classList.remove('faq__single--active');
            }
        });
    }
}
export default Faq;