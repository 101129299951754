class BackToTop {
    constructor(selector) {
        this.selector = selector;

        this._go_to_top = this._go_to_top.bind(this);

        this.selector.addEventListener('click', this._go_to_top);
    }
    _go_to_top(e) {
        e.preventDefault();

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
}
export default BackToTop;